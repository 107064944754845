import React from "react";
import User from "../Component/User/User";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../Component/Home/Home";
import Login from "../Component/Login/Login";
import LayoutMain from "../Component/Layout";
import { GetToken } from "../Component/utils";
import Contact from "../Component/User/Contact";
import Category from "../Component/Category/Category";
import Quiz from "../Component/Quiz/Quiz";
import Question from "../Component/Question/Question";
import TestTable from "../Component/TestTable/TestTable";
import Bettel from "../Component/Bettel/Bettel";
import BettelReq from "../Component/Bettel/BettelReq";
import WithdrawalRequest from "../Component/WithdrawalRequest/WithdrawalRequest";
import Kyc from "../Component/Kyc/Kyc";
import Transaction from "../Component/Transaction/Transaction";
import UserDetails from "../Component/User/UserDetails";
import Bettalview from "../Component/Bettel/Bettalview";
import Setting from "../Component/Setting/Setting";
import SubCategory from "../Component/Category/SubCategory";
import SubSubCategory from "../Component/Category/SubSubCategory";
import Product from "../Component/Product/Product";
import Sale from "../Component/Sale/Sale";
import SaleReturn from "../Component/Sale/SaleReturn";
import MqChalenge from "../Component/Chalenge/MqChalenge";
import DuqeChalenge from "../Component/Chalenge/DuqeChalenge";
import MonthReports from "../Component/Chalenge/MonthReports";
import Ticket from "../Component/Ticket/Ticket";
import AddProduct from "../Component/Product/AddProduct";
import UpdateProduct from "../Component/Product/UpdateProduct";
import MonthReports2 from "../Component/MonthReports/MonthReports";
import GpgCt from "../Component/Sale/GpgCt";
import GpgDuqe from "../Component/Sale/GpgDuqe";
import GpgMq from "../Component/Sale/GpgMq";
import Faq from "../Component/Faq/Faq";
import Distribution from "../Component/MonthReports/Distribution";
import Brands from "../Component/Category/Brands";
import SliderComponent from "../Component/Category/SliderComponent";
import Blog from "../Component/Category/Blog";
import ContactsComponent from "../Component/Sale/Contacts";
import Pages from "../Component/Ticket/Pages";
import Gpgbyadmin from "../Component/MonthReports/Gpgbyadmin";
import ProductStock from "../Component/Product/ProductStock";
import UpdateProduct2 from "../Component/Product/UpdateProduct2";
import NotificationComponent from "../Component/Category/NotificationComponent";

function PageRouter() {
  function RequireAuth({ children }) {
    const token = GetToken();
    return token ? children : <Navigate to="/login" replace />;
  }
  function AlreadyLogin({ children }) {
    const token = GetToken();
    return token ? <Navigate to="/" replace /> : children;
  }
  return (
    <div>
      <Routes>
        {/* <Route path="/forgot-password" element={<AlreadyLogin> <ForgotPassword /></AlreadyLogin>} /> */}
        <Route
          path="/login"
          element={
            <AlreadyLogin>
              <Login />
            </AlreadyLogin>
          }
        />
        <Route
          element={
            <RequireAuth>
              {" "}
              <LayoutMain />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<User />} />
          <Route path="/profile" element={<UserDetails />} />
          {/* <Route path="/battel-view/:id" element={<Bettalview />} />
          <Route path="/users/contact/:id" element={<Contact />} />*/}
          <Route path="/update-product/:id" element={<UpdateProduct />} />
          <Route path="/update-product-stock/:id" element={<UpdateProduct2 />} />
          <Route path="/bettel" element={<Bettel />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/wallet" element={<Transaction />} />
          <Route path="/kyc-pending" element={<Kyc />} />
          <Route path="/winner-request" element={<BettelReq />} />
          <Route path="/withdrawal-request" element={<WithdrawalRequest />} />
          <Route path="/category" element={<Category />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/slider" element={<SliderComponent />} />
          <Route path="/notification" element={<NotificationComponent />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/product" element={<Product />} />
          <Route path="/product-stock" element={<ProductStock />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/sale" element={<Sale />} />
          <Route path="/enquiry" element={<ContactsComponent />} />
          <Route path="/gpg-ct" element={<GpgCt />} />
          <Route path="/gpg-mq" element={<GpgMq />} />
          <Route path="/gpg-duqe" element={<GpgDuqe />} />
          <Route path="/ticket" element={<Ticket />} />
          <Route path="/pages" element={<Pages />} />
          {/* <Route path="/month-reports" element={<MonthReports />} /> */}
          <Route path="/mq-challenge" element={<MqChalenge />} />
          <Route path="/duqe-challenge" element={<DuqeChalenge />} />
          <Route path="/month-reports" element={<MonthReports2 />} />
          <Route path="/commission-distribution" element={<Distribution />} />
          <Route path="/gpg-by-admin" element={<Gpgbyadmin />} />
          <Route path="/sale-return" element={<SaleReturn />} />
          <Route path="/sub-category" element={<SubCategory />} />
          <Route path="/sub-sub-category" element={<SubSubCategory />} />
        </Route>
      </Routes>
    </div>
  );
}

export default PageRouter;
